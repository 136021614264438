import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { supabase } from "../utils/createClient";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { formatDate } from "../utils/dateUtils";

export default function FullPost() {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const { postId } = useParams();
  const { language } = useContext(LanguageContext);

  async function getPost() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from("blog_content")
        .select("*")
        .eq("blog_id", postId)
        .single();
      if (error) throw error;
      !!data && setPost(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  useEffect(() => {
    getPost();
  }, []);

  return (
    <div className="min-h-screen m-3">
      {!!loading ? (
        <div className="flex justify-center">
          {language ? "Carregando..." : "Loading..."}
        </div>
      ) : (
        <>
          <div className="flex justify-center">
            <img
              src={post.image_url}
              alt="post"
              className="max-w-2xl max-h-2xl"
            />
          </div>
          <h3>
            {formatDate(post.created_at, language ? "pt-br" : "en", "long")}
          </h3>
          <b>
            <h1>{language ? post.title_ptbr : post.title}</h1>
          </b>
          <br />
          <div
            className="justify-text"
            dangerouslySetInnerHTML={{
              __html: language ? post.content_ptbr : post.content,
            }}
          />
        </>
      )}
    </div>
  );
}
