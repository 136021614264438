import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import FullPost from "./pages/FullPost";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { LanguageContextProvider } from "./contexts/LanguageContextProvider";

function App() {
  return (
    <LanguageContextProvider>
      <div className="font-mono">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="posts/:postId" element={<FullPost />} />
        </Routes>
        <Footer />
      </div>
    </LanguageContextProvider>
  );
}

export default App;
