import { useState } from "react";
import { LanguageContext } from "./LanguageContext";

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(true);

  const toggleLanguage = () => {
    setLanguage(!language);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
