export const formatDate = (date, locale, month) => {
  const options = {
    year: "numeric",
    month: month,
    day: "2-digit",
  };

  const response = new Date(date).toLocaleDateString(locale, options);
  return month === "long"
    ? response
    : response.replace(" de ", "/").replace(". de ", "/");
};
