import { useContext } from 'react';
import { LanguageContext } from "../contexts/LanguageContext";

export default function Footer() {
  const { language } = useContext(LanguageContext);

  return (
    <footer className="flex flex-row justify-between items-center h-16 bg-black text-white w-full">
      <div className="m-3">{language ? "Todos os direitos (e esquerdos) reservados." : "All rights (and lefts) reserved."}</div>
    </footer>
  );
}
