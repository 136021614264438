import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../utils/createClient";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { formatDate } from "../utils/dateUtils";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  async function getPosts() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from("blog_content")
        .select("blog_id, title, title_ptbr, created_at")
        .order("created_at", { ascending: false });

      if (error) throw error;
      !!data && setPosts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="mt-5 min-h-screen">
      {!!loading ? (
        <div className="flex justify-center">
          {language ? "Carregando..." : "Loading..."}
        </div>
      ) : (
        posts.map((post) => (
          <div className="mb-2">
            <span className="ml-3 mr-1">&#8226;</span>
            <Link to={`/posts/${post.blog_id}`}>
              {`${language ? post.title_ptbr : post.title} - ${formatDate(
                post.created_at,
                language ? "pt-br" : "en",
                "short"
              )}`}
            </Link>
            <br />
          </div>
        ))
      )}
    </div>
  );
}
