import React from "react";
import { Link } from "react-router-dom";
import LanguageToggleSwitch from "./LanguageToggleSwitch";

export default function Header() {
  return (
    <header className="flex flex-row justify-between items-center h-16 bg-black text-white">
      <Link to={""} className="m-3">
        Legendary Redfox
      </Link>
      <LanguageToggleSwitch />
    </header>
  );
}
