import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import "./LanguageToggleSwitch.css";

export default function LanguageToggleSwitch() {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <div className="flex flex-row items-center text-lg gap-2 mr-3">
      <span>&#x1F1FA;&#x1F1F8;</span>
      <div className="toggle-slider">
        <input
          type="checkbox"
          id="toggle"
          checked={language}
          onChange={toggleLanguage}
        />
        <label htmlFor="toggle" className="slider" />
      </div>
      <span>&#x1F1E7;&#x1F1F7;</span>
    </div>
  );
}
